import React, { Component } from "react";
import firebase from "../../Firebase.js";

import AddPuzzleContainer from "./AddPuzzleContainer.jsx";

import "./AdminPageContainer.css";
import { Container, Loader, Header } from "semantic-ui-react";

export default class AdminPageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginCheckInProgress: true,
      isLoggedInAsModerator: false,
    };
  }
  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        var isAnonymous = user.isAnonymous;
        var uid = user.uid;

        user
          .getIdTokenResult()
          .then(({ claims }) => {
            if (claims.isModerator) {
              this.setState({
                isLoggedInAsModerator: true,
                isLoginCheckInProgress: false,
              });
              return;
            }
            this.setState({
              isLoggedInAsModerator: false,
              isLoginCheckInProgress: false,
            });
            return;
          })
          .catch((error) => console.log(error));
      } else {
        // User is signed out.
        // Should we do something ?
        this.setState({
          isLoginCheckInProgress: false,
        });
      }
    });
  }

  render() {
    const { isLoginCheckInProgress, isLoggedInAsModerator } = this.state;

    if (isLoginCheckInProgress) {
      return (
        <Container className="admin-page">
          <Loader />
        </Container>
      );
    }
    if (!isLoggedInAsModerator) {
      return (
        <Container className="admin-page">
          <Header>
            You are not logged in, or is not logged in as a Moderator. Go to
            home page, login with Google and come back here
          </Header>
        </Container>
      );
    }
    return <AddPuzzleContainer />;
  }
}
