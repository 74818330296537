import React, { Component } from "react";
import { Loader, Dimmer } from "semantic-ui-react";
import firebase from "../Firebase.js";

import "./MainGameContainer.css";
import MainGameContainer from "./MainGameContainer";
import UserContext from "../data/UserContext.js";
import LoadingGameContainer from "./LoadingGameContainer.jsx";
import NoMorePuzzlesPage from "./NoMorePuzzlesPage.jsx";
import ResultAudioManager from "../audio/ResultAudioManager.js";
import Analytics from "../data/Analytics.js";

const functions = firebase.app().functions("asia-east2");

const getPuzzle = functions.httpsCallable("getPuzzleCallable");

const DimmedLoader = ({ className, active, content, children }) => {
  return (
    <Dimmer.Dimmable className={className} blurring dimmed={active}>
      <Dimmer active={active} inverted>
        <Loader active content={content}></Loader>
      </Dimmer>
      {children}
    </Dimmer.Dimmable>
  );
};

export default class GameController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      puzzleData: null,
      puzzleNotFound: false,
    };
  }

  fetchPuzzleData = (puzzleNo) => {
    getPuzzle({
      puzzleNo,
    })
      .then((result) => {
        // Read result of the Cloud Function.
        var puzzleData = result.data;
        this.setState({ puzzleData });
      })
      .catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        // ...
        if (code == "not-found") {
          this.setState({ puzzleNotFound: true });
        }
      });
  };

  componentDidMount() {
    const { puzzleNo } = this.props;
    this.fetchPuzzleData(puzzleNo);
    if (puzzleNo === 1) {
      ResultAudioManager.playAudio("GAME_START");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { puzzleNo } = this.props;
    if (puzzleNo !== prevProps.puzzleNo) {
      this.fetchPuzzleData(puzzleNo);
    }
  }

  onNext = () => {
    this.setState({ puzzleData: null });
    this.props.onNext();
  };

  render() {
    const { puzzleData, puzzleNotFound } = this.state;
    const { onBack, onShowLeaderboard } = this.props;
    if (puzzleNotFound) {
      return (
        <NoMorePuzzlesPage
          onBack={onBack}
          onShowLeaderboard={onShowLeaderboard}
        />
      );
    }
    if (!puzzleData) {
      return (
        <div>
          <DimmedLoader active>
            <LoadingGameContainer />
          </DimmedLoader>
        </div>
      );
    }
    return (
      <UserContext.Consumer>
        {({ updateUserDetails }) => {
          return (
            <MainGameContainer
              onBack={onBack}
              puzzleData={puzzleData}
              onNext={this.onNext}
              updateUserDetails={updateUserDetails}
            />
          );
        }}
      </UserContext.Consumer>
    );
  }
}
