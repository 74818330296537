import React, { Component } from "react";
import firebase from "./Firebase.js";

import UserContext from "./data/UserContext";
import GameController from "./containers/GameController";
import HomePageContainer from "./containers/HomePageContainer";
import LeaderboardContainer from "./containers/LeaderboardContainer.jsx";
import ResultAudioManager from "./audio/ResultAudioManager.js";
import Analytics from "./data/Analytics.js";
import UserSettingsLS from "./data/UserSettingsLS.js";

const Pages = {
  Home: "HOME",
  Game: "GAME",
  Leaderboard: "LEADERBOARD",
};

const functions = firebase.app().functions("asia-east2");

const getUserDetails = functions.httpsCallable("getUserDetails");

const resetLevels = functions.httpsCallable("resetLevels");

export default class AppStateController extends Component {
  constructor(props) {
    super(props);
    const isMute = UserSettingsLS.getIsMute();
    this.state = {
      puzzleNo: 1,
      userDetails: null,
      isLoginInProgress: true,
      startAfterLoggingIn: false,
      user: null,
      page: Pages.Home,
      settings: {
        isMute,
      },
    };
    if (isMute) {
      ResultAudioManager.setIsMute(isMute);
    }
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        var isAnonymous = user.isAnonymous;
        var uid = user.uid;

        const googleUser = user.providerData.find(
          (provider) => provider.providerId === "google.com"
        );

        let detailsToUpdate = null;
        if (googleUser) {
          detailsToUpdate = {
            name: googleUser.displayName,
            picture: googleUser.photoURL,
          };
        }

        getUserDetails({ detailsToUpdate })
          .then((result) => {
            const userDetails = result.data;

            this.setState(
              {
                isLoginInProgress: false,
                user,
                userDetails: userDetails,
                puzzleNo: userDetails.puzzleNo,
              },
              () => {
                const { startAfterLoggingIn } = this.state;
                if (startAfterLoggingIn) {
                  this.setState({ page: Pages.Game });
                }
              }
            );
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              isLoginInProgress: false,
              startAfterLoggingIn: false,
            });
          });
      } else {
        // User is signed out.
        // Should we do something ?
        this.setState({
          isLoginInProgress: false,
        });
      }
    });

    firebase
      .auth()
      .getRedirectResult()
      .then(function (result) {
        if (result.credential) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.idToken;

          var credential = firebase.auth.GoogleAuthProvider.credential(token);

          firebase
            .auth()
            .currentUser.linkWithCredential(credential)
            .then(function (usercred) {
              var user = usercred.user;
              console.log("Anonymous account successfully upgraded", user);
            })
            .catch(function (error) {
              console.log("Error upgrading anonymous account", error);
            });
          // ...
        }
        // The signed-in user info.
        var user = result.user;
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
  }

  goToHome = () => {
    this.setState({ page: Pages.Home });
  };

  goToLeaderboard = () => {
    this.setState({ page: Pages.Leaderboard });
  };

  startGame = () => {
    const { user, userDetails } = this.state;
    if (!user) {
      this.setState({ isLoginInProgress: true, startAfterLoggingIn: true });
      firebase
        .auth()
        .signInAnonymously()
        .catch(function (error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          // TODO: Show error to user
          this.setState({
            isLoginInProgress: false,
            startAfterLoggingIn: false,
          });
        });
      return;
    }
    if (!userDetails) {
      //Still loading,
      this.setState({ isLoginInProgress: true, startAfterLoggingIn: true });
      return;
    }
    this.setState({ page: Pages.Game });
  };

  updateUser = (user) => {
    this.setState({ user });
  };

  signInWithGoogle = () => {
    this.setState({ isLoginInProgress: true });
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase.auth().signInWithRedirect(provider);
  };

  toggleAudio = () => {
    this.setState(
      ({ settings }) => {
        const { isMute } = settings;

        return { settings: { ...settings, isMute: !isMute } };
      },
      () => {
        const {
          settings: { isMute },
        } = this.state;
        ResultAudioManager.setIsMute(isMute);
        Analytics.changedAudioStatus(isMute);
        UserSettingsLS.setIsMute(isMute);
      }
    );
  };

  signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.setState({ user: null });
      })
      .catch(function (error) {
        // An error happened.
      });
  };

  goToNextPuzzle = () => {
    this.setState(({ puzzleNo }) => {
      return { puzzleNo: puzzleNo + 1 };
    });
  };

  onResetLevels = () => {
    resetLevels().then(() => {
      this.setState(({ userDetails }) => {
        const updatedUserDetails = {
          ...userDetails,
          puzzleNo: 1,
        };
        return { userDetails: updatedUserDetails };
      });
    });
  };

  updateUserDetails = (userDetails) => {
    this.setState({ userDetails });
    Analytics.updateUserTokens(userDetails.tokens);
  };

  render() {
    const {
      user,
      userDetails,
      isLoginInProgress,
      puzzleNo,
      page,
      settings,
    } = this.state;
    return (
      <UserContext.Provider
        value={{
          user,
          userDetails,
          isLoginInProgress,
          settings,
          updateUser: this.updateUser,
          signInWithGoogle: this.signInWithGoogle,
          signOut: this.signOut,
          goToNextPuzzle: this.goToNextPuzzle,
          onResetLevels: this.onResetLevels,
          updateUserDetails: this.updateUserDetails,
          toggleAudio: this.toggleAudio,
        }}
      >
        {page === Pages.Home && (
          <HomePageContainer
            onStart={this.startGame}
            onShowLeaderboard={this.goToLeaderboard}
          />
        )}
        {page === Pages.Game && (
          <GameController
            onBack={this.goToHome}
            onShowLeaderboard={this.goToLeaderboard}
            onNext={this.goToNextPuzzle}
            puzzleNo={puzzleNo || 1}
            user={user}
          />
        )}
        {page === Pages.Leaderboard && (
          <LeaderboardContainer onBack={this.goToHome} />
        )}
      </UserContext.Provider>
    );
  }
}
