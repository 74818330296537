const OS = {
  Android: "ANDROID",
  iOS: "IOS",
  WindowsPhone: "WINDOWS_PHONE",
  Others: "OTHERS",
};

class SocialLinkGenerator {
  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   *
   * @returns {String}
   */
  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return OS.WindowsPhone;
    }

    if (/android/i.test(userAgent)) {
      return OS.Android;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return OS.iOS;
    }

    return OS.Others;
  }

  getInstagramURL() {
    const os = this.getMobileOperatingSystem();
    switch (os) {
      case OS.Android:
        return "intent://instagram.com/_u/padamlive/#Intent;package=com.instagram.android;scheme=https;end";
      case OS.iOS:
        return "instagram://user?username=padamlive";
      default:
        return "https://www.instagram.com/padamlive/";
    }
  }

  getTwitterURL() {
    const os = this.getMobileOperatingSystem();
    switch (os) {
      case OS.Android:
        return "intent://twitter.com/PadamLive#Intent;package=com.twitter.android;scheme=https;end";
      case OS.iOS:
        return "twitter://user?screen_name=PadamLive";
      default:
        return "https://twitter.com/PadamLive";
    }
  }

  getFacebookURL() {
    const os = this.getMobileOperatingSystem();
    switch (os) {
      case OS.Android:
        return "intent://page/277524365784009?referrer=app_link#Intent;package=com.facebook.katana;scheme=fb;end";
      case OS.iOS:
        return "fb://page/?id=277524365784009";
      default:
        return "https://www.facebook.com/PadamLive";
    }
  }
}

export default new SocialLinkGenerator();
