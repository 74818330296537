import firebase from "../Firebase";

const analytics = firebase.analytics();

class Analytics {
  logPuzzleCompletion(puzzleNo, hintsCount) {
    analytics.logEvent("puzzle_end", { puzzleNo, hintsCount });
  }

  updatePuzzleNo(puzzleNo) {
    analytics.setUserProperties({ puzzleNo });
    analytics.logEvent("puzzle_start", { puzzleNo });
  }

  changedAudioStatus(status) {
    analytics.logEvent("audio_status", { status });
  }

  updateUserTokens(tokens) {
    analytics.setUserProperties({ tokens });
  }
}

export default new Analytics();
