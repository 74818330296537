import React, { Component } from "react";
import classnames from "classnames";
import { Modal } from "semantic-ui-react";

import "./HomePageContainer.css";
import UserContext from "../data/UserContext";
import SocialLinkGenerator from "../data/SocialLinkGenerator";

const SignInWithGoogle = ({ onClick, isLoginInProgress }) => {
  return (
    <button
      color="google plus"
      onClick={!isLoginInProgress ? onClick : null}
      className={classnames("critical", { loading: isLoginInProgress })}
    >
      Sign in with Google
    </button>
  );
};

export default class HomePageController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      user: null,
      showHowToPlay: false,
    };
  }

  openHowToPlay = () => {
    this.setState({ showHowToPlay: true });
  };

  closeHowToPlay = () => {
    this.setState({ showHowToPlay: false });
  };

  renderHowToPlay() {
    return (
      <Modal
        size={"tiny"}
        open
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>How To Play</Modal.Header>
        <Modal.Content className="play">
          <ol>
            <li>
              Guess the movie name from the four pictures and letter blocks.
              Click on a picture to enlarge it.
            </li>
            <li>Fill up the empty blocks with the letters provided. </li>
            <li>Use hint coins to get help to solve the puzzle.</li>
            <li>
              Use only the  <i className="highlight">wikipedia </i>  spelling of the movie. For example spelling
              of കയ്യൊപ്പ് is "kaiyoppu" not "kayyoppu"
            </li>
            <li>
              Each word in the movie name should be separated with a space. For
              example: It will be "kumbalangi nights", not "kumbalanginights"
            </li>
            <li>
              And finally, if you still find it hard to get the correct answer,
              get help by sharing the screenshot of the puzzle with your friends
              or contact us in our official{" "}
              <a target="_blank" href={SocialLinkGenerator.getInstagramURL()}>
                instagram
              </a>{" "}
              account
            </li>
          </ol>
        </Modal.Content>
        <Modal.Actions>
          <button onClick={this.closeHowToPlay}>Close</button>
        </Modal.Actions>
      </Modal>
    );
  }

  render() {
    const { onStart, onShowLeaderboard } = this.props;
    const { showHowToPlay } = this.state;
    return (
      <div className="game-container">
        <div className="home-page">
          <div className="homePageLogo jello bounceIn">
            <img width="100px" src="images/padam_logo.png" />
          </div>
          {showHowToPlay && this.renderHowToPlay()}
          <UserContext.Consumer>
            {({
              user,
              userDetails,
              isLoginInProgress,
              signInWithGoogle,
              signOut,
              onResetLevels,
            }) => {
              return (
                <>
                <div className="mainButtonContainer">
                  <button
                    className={classnames({ loading: isLoginInProgress })}
                    onClick={isLoginInProgress ? null : onStart}
                  >
                    Start
                  </button>
                  <button onClick={onShowLeaderboard}>Leaderboard</button>

                  <button onClick={this.openHowToPlay}>How to Play</button>
                  </div>

                  {!user || user?.isAnonymous ? (
                    <SignInWithGoogle
                      onClick={signInWithGoogle}
                      isLoginInProgress={isLoginInProgress}
                    />
                  ) : (
                    <button className="critical" onClick={signOut}>
                      Logout
                    </button>
                  )}
                  {userDetails?.puzzleNo > 1 && userDetails?.isTester && (
                    <button className="critical" onClick={onResetLevels}>
                      Reset Levels
                    </button>
                  )}
                </>
              );
            }}
          </UserContext.Consumer>
          <div className="socialMedia">
            <a href={SocialLinkGenerator.getInstagramURL()} target="_blank">
              <img src="images/instagram.png" height="32px" />
            </a>
            <a href={SocialLinkGenerator.getTwitterURL()} target="_blank">
              <img src="images/twitter.png" height="32px" />
            </a>
            <a href={SocialLinkGenerator.getFacebookURL()} target="_blank">
              <img src="images/facebook.png" height="32px" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
