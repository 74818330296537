class UserSettingsStorage {
  setIsMute(status) {
    localStorage.setItem("settings.isMute", status);
  }

  getIsMute(status) {
    return localStorage.getItem("settings.isMute") === "true";
  }
}

export default new UserSettingsStorage();
