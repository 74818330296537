import random from "lodash.random";

class AudioManager {
  constructor() {
    this.initDone = false;
    this.loadedAudio = new Audio();
    this.isMute = false;
  }

  setIsMute = (isMute) => {
    this.isMute = isMute;
  };

  playAudio = (audioType) => {
    if (this.isMute) {
      return;
    }
    let path = null;
    switch (audioType) {
      case "GAME_START":
        path = "/audio/Game_Start.mp3";
        break;
      case "GAME_END":
        path = "/audio/Game_End.mp3";
        break;
      case "WIN":
        path = `/audio/Win_${random(1, 4)}.mp3`;
        break;
      case "LOST":
        path = `/audio/Lost_${random(1, 4)}.mp3`;
        break;
    }
    this.loadedAudio.src = path;
    this.loadedAudio.play();
  };

  initAudio() {
    if (!this.initDone) {
      this.loadedAudio.play();
      this.loadedAudio.pause();
      this.initDone = true;
    }
  }
}

export default new AudioManager();
