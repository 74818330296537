import React, { Component } from "react";
import ResultAudioManager from "../audio/ResultAudioManager";

export default class NoMorePuzzlesPage extends Component {
  componentDidMount() {
    ResultAudioManager.playAudio("GAME_END");
  }

  render() {
    const { onBack, onShowLeaderboard } = this.props;
    return (
      <div className="game-container">
        <div className="lastPage">
          <div className="homePageLogo  bounceIn">
            <img width="100px" src="./images/padam_logo.png" />
          </div>
          <div className="gameEnd">
            <div className="messageBox">
              <h3>സബാഷ്!!</h3>
              <p className="message">
                ഇത്രയും ക്ഷമയും, ബുദ്ധിശക്തിയും, മത്സര മനോഭാവവും ഞാനെന്റെ ചാൾസ്
                ശോഭാ രാജിൽ മാത്രമേ കണ്ടിട്ടുള്ളൂ! എവിടുന്ന് കിട്ടി കുട്ടീ,
                നിനക്കിത്ര ധൈര്യം? പക്ഷേ, ആയുധം താഴെ വെക്കാൻ വരട്ടെ! ഇതിലും
                മികവോടെ, ഇതിൽപരം മാറ്റങ്ങളോടെ, ഇനിയുമേറെ ചിന്തിപ്പിക്കുന്ന ഒരു
                കൂട്ടം ചോദ്യങ്ങളുമായി ഞങ്ങൾ വീണ്ടും വരും. നിങ്ങൾ തയ്യാറല്ലേ?
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <button onClick={onBack}>Home</button>
              <button onClick={onShowLeaderboard}>Leaderboard</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
