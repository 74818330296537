import React from "react";

const UserContext = React.createContext({
  user: null,
  puzzleNo: 1,
  isLoginInProgress: false,
  userDetails: null,
  settings: {
    isMute: false,
  },
  updateUser: () => {},
  updateUserDetails: () => {},
  signInWithGoogle: () => {},
  signOut: () => {},
  goToNextPuzzle: () => {},
  toggleMute: () => {},
});
UserContext.displayName = "UserContext";

export default UserContext;
