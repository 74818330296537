import React, { Component } from "react";
import { Grid, Image, Transition } from "semantic-ui-react";
import chunk from "lodash.chunk";

import "./PicsGrid.css";

const ClickableImage = ({ pic, onClick }) => {
  const handleOnClick = () => onClick(pic);

  return (
    <div
      className="image-holder"
      style={{ backgroundImage: "url(" + pic + ")" }}
      onClick={handleOnClick}
    >
      {/* <div className="loadingIndicator"></div> */}
    </div>
  );
};

export default class PicsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImage: null,
      showExpandedImage: false,
    };
  }

  expandImage = (pic) => {
    this.setState({ selectedImage: pic, showExpandedImage: true });
  };

  collapseImage = () => {
    this.setState({ showExpandedImage: false });
  };

  render() {
    const { pics } = this.props;
    const { selectedImage, showExpandedImage } = this.state;
    const [firstTwo, lastTwo] = chunk(pics, 2);
    return (
      <div className={"pics-container"}>
        <Transition visible={showExpandedImage} animation="zoom" duration={500}>
          <Image
            className={"expanded-pic"}
            size={"large"}
            src={selectedImage}
            onClick={this.collapseImage}
          />
        </Transition>
        <div>
          <div className="pics-row">
            {firstTwo.map((pic) => {
              return (
                <div key={pic}>
                  <ClickableImage onClick={this.expandImage} pic={pic} />
                </div>
              );
            })}
          </div>
          <div className="pics-row">
            {lastTwo.map((pic) => {
              return (
                <div key={pic}>
                  <ClickableImage pic={pic} onClick={this.expandImage} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
