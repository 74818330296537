import React, { Component } from "react";
import PicsGrid from "../components/PicsGrid";
import InteractionPanel from "../components/InteractionPanel";
import UserContext from "../data/UserContext";

import "./MainGameContainer.css";

export default class LoadingGameContainer extends Component {
  render() {
    const puzzleNo = 999;
    const answerLength = 10;
    const pics = new Array(4).fill("images/padam_logo.png");
    const hints = "padamisawesome".split("");

    return (
      <div className="game-container">
        <div>
          <UserContext.Consumer>
            {({ userDetails }) => {
              return (
                <div className={"top-row-wrap"}>
                  <div>
                    <button
                      content=""
                      labelPosition="right"
                      disabled={userDetails.tokens < 50}
                    >
                      Hint (50)
                    </button>
                  </div>
                  <div className="iconButton">
                    <div className="iconHolder">
                      <img src="images/coins.png" height="20px"></img>
                    </div>
                    <div className="value">{userDetails.tokens || 0}</div>
                  </div>
                </div>
              );
            }}
          </UserContext.Consumer>
        </div>
        <p className="puzzleLevel">
          Level <span className="puzzleLevelNumber">#{puzzleNo}</span>
        </p>
        <div className={"pics-and-grid"}>
          <PicsGrid pics={pics} />
          <InteractionPanel answerSize={answerLength} hints={hints} />
        </div>
      </div>
    );
  }
}
