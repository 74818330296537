import React, { Component } from "react";
import { Button, Loader, Image } from "semantic-ui-react";
import firebase from "../Firebase.js";

import "./LeaderboardContainer.css";

const functions = firebase.app().functions("asia-east2");

const getLeaderboard = functions.httpsCallable("getLeaderboard");

export default class LeaderboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    getLeaderboard()
      .then((result) => {
        const entries = result.data;
        this.setState({ entries, isLoading: false });
      })
      .catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;

        console.log(error);
      });
  }

  render() {
    const { isLoading, entries } = this.state;
    const { onBack } = this.props;
    return (
      <div className="leader-list game-container">
        <div className="horizontalPanel">
          <button className="hintButton" onClick={onBack}>
            <div className="iconButton">
              <div className="iconHolder">
                <img src="images/backIcon.png" height="16px"></img>
              </div>
            </div>
          </button>
          <h3 className="leaderboard-header">Leaderboard</h3>
        </div>
        <div className={"leader-list-container"}>
          <Loader active={isLoading} />
          <div className="leader-list-holder">
            {entries.map((user, index) => {
              return (
                <div key={index} className="leader-list-item">
                  <Image
                    avatar
                    src={user.picture || "/images/profile_image.png"}
                  />
                  <p className="leader-level">{index + 1}</p>
                  <p className="leader-name">{user.name || "Anonymous"}</p>
                  <img src="/images/coins.png" className="leader-trophy"></img>
                  <p className="leader-point" floated="right">
                    {user.puzzleNo}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
