import React, { Component, useCallback, useState } from "react";
import firebase from "../../Firebase.js";

import "firebase/storage";

import {
  Header,
  Container,
  Form,
  Input,
  Label,
  Button,
  Message,
  Segment,
  Icon,
  Grid,
  Checkbox,
} from "semantic-ui-react";
import shuffle from "lodash.shuffle";

import { useDropzone } from "react-dropzone";

import "./AdminPageContainer.css";

const storage = firebase.storage();
const ref = storage.ref();

const functions = firebase.app().functions("asia-east2");

const addPuzzle = functions.httpsCallable("addPuzzle");

const MAX_ANSWER_SIZE = 21;
const MAX_HINTS_SIZE = 21;

const LIFE_CYCLE = {
  Start: "START",
  PuzzleCreated: "PUZZLE_CREATED",
  ImagesUploaded: "IMAGES_UPLOADED",
};

const validateFilesList = (files) => {
  const errors = [];

  if (files.length !== 5) {
    errors.push("Number of files should be 5");
    return errors;
  }

  files.forEach((file) => {
    if (file.type !== "image/jpeg") {
      errors.push("only jpeg (.jpeg, or .jpg) files allowed");
    }
  });

  const fileNames = files.map((file) => file.name.split(".")[0].trim());

  const hasAllFileNames = ["1", "2", "3", "4", "5"].every(
    (fileName) => fileNames.indexOf(fileName) > -1
  );

  if (!hasAllFileNames) {
    errors.push("Not all files are there, or file names are messed up");
  }

  return errors;
};

const FileDropzone = ({ onConfirm, isSendingToServer }) => {
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState([]);
  const removeFromFiles = (event, { index }) => {
    setFiles(files.filter((value, i) => i !== index));
  };
  const onDrop = useCallback((acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const onUploadClick = () => {
    const fileErrors = validateFilesList(files);
    if (fileErrors.length) {
      setErrors(fileErrors);
      return;
    }
    setErrors([]);

    onConfirm(files);
  };

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Segment placeholder>
          <Header icon>
            <Icon size="huge" name="file" />
            Drag and Drop files or click to browse
          </Header>
        </Segment>
      </div>
      {files.length ? (
        <Segment>
          <Container>
            <Grid columns={5}>
              <Grid.Row>
                {files.map((file, index) => {
                  const { name } = file;
                  return (
                    <Label key={index} index={index} onClick={removeFromFiles}>
                      {name} <Icon name="delete" />
                    </Label>
                  );
                })}
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      ) : (
        []
      )}
      {errors.length ? <Message negative>{errors[0]}</Message> : []}
      <Button positive loading={isSendingToServer} onClick={onUploadClick}>
        Upload
      </Button>
    </>
  );
};

export default class AddPuzzleContainer extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      data: { puzzleNo: null, movieName: null, movieHints: null },
      errorMessage: null,
      isSendingToServer: false,
      successMessage: null,
      stage: LIFE_CYCLE.Start,
      showOverwriteOption: false,
      overwrite: false,
    };
  };

  onSubmit = () => {
    const errorMessage =
      this.validatePuzzleNo(true) ||
      this.validateMovieName(true) ||
      this.validateMovieHints(true);

    if (errorMessage) {
      this.setState({ errorMessage }, this.autoDismissError);
      return;
    }

    this.setState({ errorMessage: null, isSendingToServer: true });

    const { data, overwrite } = this.state;

    addPuzzle({ ...{ ...data, movieName: data.movieName.trim() }, overwrite })
      .then((result) => {
        this.setState({
          successMessage: "Puzzle created!",
          isSendingToServer: false,
          stage: LIFE_CYCLE.PuzzleCreated,
        });
      })
      .catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;

        this.setState(
          {
            errorMessage: message,
            isSendingToServer: false,
          },
          this.autoDismissError
        );

        if (code === "already-exists") {
          this.setState({ showOverwriteOption: true });
        }
      });
  };

  onPuzzleNoChange = (event, value) => {
    this.setState(({ data }) => {
      return {
        data: {
          ...data,
          puzzleNo: value.value,
        },
      };
    });
  };

  onMovieNameChange = (event, value) => {
    this.setState(({ data }) => {
      const updatedMovieName = value.value;
      let updatedHints = null;

      if (updatedMovieName) {
        updatedHints = shuffle(
          updatedMovieName.toLowerCase().trim().split("")
        ).join("");
      }

      return {
        data: {
          ...data,
          movieName: value.value,
          movieHints: updatedHints,
        },
      };
    });
  };

  validatePuzzleNo = (doOptionalCheck) => {
    const {
      data: { puzzleNo },
    } = this.state;
    if (doOptionalCheck) {
      if (puzzleNo == null) {
        return "Puzzle No is Mandatory";
      }
    }
  };

  validateMovieName = (doOptionalCheck) => {
    const {
      data: { movieName },
    } = this.state;
    if (doOptionalCheck) {
      if (movieName == null) {
        return "Movie Name is Mandatory";
      }
    }
    if (movieName && movieName.length > MAX_ANSWER_SIZE) {
      return `Movie Name cant have more than ${MAX_ANSWER_SIZE} characters`;
    }
  };

  validateMovieName = (doOptionalCheck) => {
    const {
      data: { movieName },
    } = this.state;
    if (doOptionalCheck) {
      if (movieName == null) {
        return "Movie Name is Mandatory";
      }
    }
    if (movieName && movieName.length > MAX_ANSWER_SIZE) {
      return `Movie Name cant have more than ${MAX_ANSWER_SIZE} characters`;
    }
  };

  validateMovieHints = (doOptionalCheck) => {
    const {
      data: { movieHints, movieName },
    } = this.state;
    if (doOptionalCheck) {
      if (movieHints == null) {
        return "Hints are Mandatory";
      }
    }
    if (movieHints && movieHints.length > MAX_HINTS_SIZE) {
      return `Hints cant have more than ${MAX_HINTS_SIZE} characters`;
    }
    if (movieName && movieHints) {
      const charArray = movieName.toLowerCase().trim().split("");
      const hintsArray = movieHints.toLowerCase().split("");
      let hintsMissingChar = false;
      charArray.forEach((char) => {
        const index = hintsArray.indexOf(char);
        if (index < 0) {
          hintsMissingChar = true;
          return;
        }
        hintsArray.splice(index, 1);
      });
      if (hintsMissingChar) {
        return "Hints does not have all the characters of the movie name";
      }
    }
  };

  toggleOverwrite = () => {
    this.setState(({ overwrite }) => {
      return { overwrite: !overwrite };
    });
  };

  onMovieHintChange = (event, value) => {
    this.setState(({ data }) => {
      return {
        data: {
          ...data,
          movieHints: value.value,
        },
      };
    });
  };

  autoDismissError = () => {
    setTimeout(() => {
      this.setState({ errorMessage: null });
    }, 3000);
  };

  uploadFiles = (files) => {
    const {
      data: { movieName, puzzleNo },
    } = this.state;

    const promises = [];
    files.forEach((file) => {
      let { name } = file;
      name = name.split(".")[0].trim();
      if (name === "5") {
        name = movieName;
      }

      const task = ref
        .child(`/padams/${puzzleNo}/${name.toLowerCase().trim()}.jpg`)
        .put(file, {
          contentType: file.type,
        });

      promises.push(task);
    });

    this.setState({ isSendingToServer: true, errorMessage: null });
    Promise.all(promises)
      .then(() => {
        this.setState({
          isSendingToServer: false,
          successMessage: "All Files Uploaded",
          stage: LIFE_CYCLE.ImagesUploaded,
        });
      })
      .catch((errors) => {
        this.setState({ isSendingToServer: false, errorMessage: errors });
      });
  };

  reset = () => {
    this.setState(this.getInitialState());
  };

  render() {
    const {
      errorMessage,
      successMessage,
      isSendingToServer,
      stage,
      showOverwriteOption,
      overwrite,
      data: { movieName, movieHints, puzzleNo },
    } = this.state;
    const puzzleError = this.validatePuzzleNo();
    const movieNameError = this.validateMovieName();
    const movieHintsError = this.validateMovieHints();

    if (stage == LIFE_CYCLE.Start) {
      return (
        <div className="admin-page">
          <Container>
            <Header size="large">Add a puzzle</Header>
            <Form onSubmit={this.onSubmit}>
              <Form.Group>
                <Form.Field>
                  <label>Puzzle No</label>
                  <Input
                    value={puzzleNo}
                    error={!!puzzleError}
                    type={"number"}
                    placeholder="Puzzle No"
                    onChange={this.onPuzzleNoChange}
                  />
                  {puzzleError && (
                    <Label basic color="red" pointing>
                      {puzzleError}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Movie Name</label>
                  <Input
                    value={movieName}
                    error={!!movieNameError}
                    placeholder="Movie Name"
                    onChange={this.onMovieNameChange}
                  />
                  {movieNameError && (
                    <Label basic color="red" pointing>
                      {movieNameError}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Hints</label>
                  <Input
                    value={movieHints}
                    error={!!movieHintsError}
                    placeholder="Hints"
                    onChange={this.onMovieHintChange}
                  />
                  {movieHintsError && (
                    <Label basic color="red" pointing>
                      {movieHintsError}
                    </Label>
                  )}
                </Form.Field>
              </Form.Group>
              {showOverwriteOption ? (
                <Form.Field>
                  <Checkbox
                    value={overwrite}
                    toggle
                    onChange={this.toggleOverwrite}
                    label="Overwrite the puzzle (BE CAREFUL!)"
                  />
                </Form.Field>
              ) : (
                []
              )}
              {errorMessage ? <Message negative>{errorMessage}</Message> : null}
              <Button type="submit" loading={isSendingToServer}>
                Create Puzzle
              </Button>
            </Form>
          </Container>
        </div>
      );
    }

    if (stage === LIFE_CYCLE.PuzzleCreated) {
      return (
        <div className="admin-page">
          <Container></Container>
          {successMessage ? <Message positive>{successMessage}</Message> : null}
          <FileDropzone
            onConfirm={this.uploadFiles}
            isSendingToServer={isSendingToServer}
          />
          {errorMessage ? <Message negative>{errorMessage}</Message> : null}
        </div>
      );
    }

    if (stage === LIFE_CYCLE.ImagesUploaded) {
      return (
        <div className="admin-page">
          <Container></Container>
          {successMessage ? <Message positive>{successMessage}</Message> : null}
          <Button positive onClick={this.reset}>
            Create Another Puzzle
          </Button>
        </div>
      );
    }
  }
}
