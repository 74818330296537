import React, { Component } from "react";
import { Image, Loader, Icon, Modal } from "semantic-ui-react";
import chunk from "lodash.chunk";
import classnames from "classnames";

import "./InteractionPanel.css";
import ResultAudioManager from "../audio/ResultAudioManager";
import UserContext from "../data/UserContext";

const createImage = (puzzleNo, answerArray) => {
  const movieName = answerArray.map((item) => item.value).join("");
  return `https://firebasestorage.googleapis.com/v0/b/padam-webapp-2020.appspot.com/o/${encodeURIComponent(
    `padams/${puzzleNo}/${movieName}`
  )}.jpg?alt=media`;
};

const Cell = ({ value, index, onClick, isHint, disabled }) => {
  const handleOnClick = () => {
    value && !disabled && !isHint && onClick(index, value);
  };

  let char = value;

  const isSpace = value === " ";

  const classNames = classnames("cell", {
    inactive: !value,
    "is-hint": isHint,
    space: isSpace,
  });

  if (isSpace) {
    char = "";
  }

  return (
    <div className={classNames} onClick={handleOnClick}>
      {char}
    </div>
  );
};

const VerificationMessage = ({
  puzzleNo,
  verifying,
  verificationResult,
  onNext,
  hasInformedServer,
  hasServerErrored,
  answerArray,
}) => {
  if (verifying) {
    return <Loader active />;
  }
  if (!verificationResult) {
    return null;
  }
  switch (verificationResult) {
    case "SUCCESS":
      return (
        <Modal
          size={"mini"}
          open
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Wow!</Modal.Header>
          <Modal.Content>
            <img src="images/poli.png"></img>
            <UserContext.Consumer>
              {({ user }) => {
                if (puzzleNo > 5 && puzzleNo % 5 === 0 && user.isAnonymous) {
                  return (
                    <>
                      <br />
                      <span>
                        Sign in with Google to ensure that your progress is
                        saved!
                      </span>
                    </>
                  );
                }
                return null;
              }}
            </UserContext.Consumer>
          </Modal.Content>
          <Modal.Actions>
            <button
              onClick={onNext}
              className={classnames({ loading: !hasInformedServer })}
              disabled={!hasInformedServer || hasServerErrored}
            >
              Next Level
            </button>
          </Modal.Actions>
        </Modal>
      );
    case "FAILURE":
      return (
        <div className=" wobble autoClosePopup verification-error label">
          <Icon
            name="close"
            color="yellow"
            circular
            inverted
            className="icon"
            size="small"
            style={{
              marginRight: "10px !important",
              color: "#000000 !important",
            }}
          />
          <p>കലങ്ങീല്ല!</p>
        </div>
      );
    case "ERROR":
      return (
        <div className=" wobble autoClosePopup verification-error label">
          <Icon
            name="close"
            color="yellow"
            circular
            inverted
            className="icon"
            size="small"
            style={{ marginRight: "10px !important" }}
          />
          Couldn't verify. Try again?
        </div>
      );
  }
  throw new Error(`Unknown status: ${verificationResult}`);
};

const ELEMENTS_IN_A_ROW = 7;

export default class InteractionPanel extends Component {
  constructor(props) {
    super(props);
    const { answerSize, hints } = props;
    this.state = {
      answerArray: new Array(answerSize).fill(null),
      hints: hints,
      verifying: false,
    };
  }

  clearAll = () => {
    const { answerArray } = this.state;
    answerArray.forEach((item, index) => {
      if (item && !item.isHint) {
        this.removeFromAnswer(index, item.value);
      }
    });
  };

  removeFromAnswer = (index, value) => {
    this.setState(({ hints, answerArray }) => {
      const newAnswerArray = [...answerArray];
      newAnswerArray[index] = null;

      const newHints = [...hints];
      newHints[answerArray[index].originalIndex] = value;

      return {
        hints: newHints,
        answerArray: newAnswerArray,
        verificationResult: null,
      };
    });
  };

  addToAnswer = (index, value) => {
    ResultAudioManager.initAudio();
    this.setState(({ hints, answerArray }) => {
      const newHints = [...hints];
      newHints[index] = null;

      const firstEmptyIndex = answerArray.findIndex((el) => el === null);
      if (firstEmptyIndex < 0) {
        // Nothing free, return
        return null;
      }
      const newAnswerArray = [...answerArray];
      newAnswerArray[firstEmptyIndex] = {
        value,
        originalIndex: index,
        isHint: false,
      };
      return { hints: newHints, answerArray: newAnswerArray };
    }, this.checkIfComplete);
  };

  componentDidUpdate(prevProps, prevState) {
    const { knownHints, hints: allHints } = this.props;

    if (knownHints !== prevProps.knownHints) {
      const newHint = knownHints[knownHints.length - 1];

      this.setState(({ hints, answerArray }) => {
        const { index, char } = newHint;
        const newHints = [...hints];
        const newAnswerArray = [...answerArray];

        //If the cell has something already, remove and add back to hints
        if (!!answerArray[index]) {
          newHints[answerArray[index].originalIndex] = answerArray[index].value;
        }

        //Update the cell with the hint
        newAnswerArray[index] = {
          value: char,
          isHint: true,
        };

        //Find the char in hints and remove it
        let newHintIndex = newHints.findIndex((el) => el === char);
        if (newHintIndex < 0) {
          //Did not find it, perhaps user is using it somewhere?
          //Find a non Hint entry in answer and remove it
          const usersGuessIndex = newAnswerArray.findIndex(
            (el) => !!el && !el.isHint && el.value === char
          );
          newAnswerArray[usersGuessIndex] = null;
        } else {
          //Found it, just remove from hints
          newHints[newHintIndex] = null;
        }

        return { hints: newHints, answerArray: newAnswerArray };
      });
    }
  }

  checkIfComplete = () => {
    const { answerArray } = this.state;
    const completedEntry = answerArray.every((entry) => !!entry);
    if (!completedEntry) {
      // clear message
      this.setState({ verificationResult: null });
      return;
    }
    const { onComplete } = this.props;
    this.setState({ verifying: true });
    onComplete(answerArray.map((el) => el.value).join("")).then((status) => {
      this.setState(
        {
          verificationResult: status,
          verifying: false,
        },
        this.autoDismissVerificationError
      );
    });
  };

  autoDismissVerificationError = () => {
    const { verificationResult } = this.state;
    if (verificationResult === "FAILURE" || verificationResult === "ERROR") {
      setTimeout(() => {
        const { verificationResult } = this.state;
        if (
          verificationResult === "FAILURE" ||
          verificationResult === "ERROR"
        ) {
          this.setState({ verificationResult: null });
        }
      }, 3000);
    }
  };

  createAnswerRows = () => {
    const { answerArray } = this.state;

    const answerRows = chunk(answerArray, ELEMENTS_IN_A_ROW);

    if (answerRows.length == 1) {
      return answerRows;
    }

    let lastRow = answerRows[answerRows.length - 1];

    if (lastRow.length == 1) {
      //If there is only one element here, take 2 from previous row and add it here.
      const prevRow = answerRows[answerRows.length - 2];
      const lastTwo = prevRow.splice(-2);
      lastRow = [...lastTwo, ...lastRow];
      answerRows[answerRows.length - 1] = lastRow;
    }

    return answerRows;
  };

  render() {
    const { hints, answerArray, verifying, verificationResult } = this.state;
    const {
      puzzleNo,
      onNext,
      hasInformedServer,
      hasServerErrored,
    } = this.props;
    const chunkedHints = chunk(hints, ELEMENTS_IN_A_ROW);
    const isDisabled = verifying || verificationResult === "SUCCESS";
    const answerRows = this.createAnswerRows();
    let answerIndex = -1;
    return (
      <div className="play-grid">
        <div className="result-section">
          <VerificationMessage
            puzzleNo={puzzleNo}
            verifying={verifying}
            verificationResult={verificationResult}
            onNext={onNext}
            hasInformedServer={hasInformedServer}
            hasServerErrored={hasServerErrored}
            answerArray={answerArray}
          />
        </div>
        <div className="answer-grid">
          {answerRows.map((row, index) => {
            return (
              <div key={index} className="answer-row">
                {row.map((item) => {
                  const itemIndex = ++answerIndex;
                  return (
                    <Cell
                      key={itemIndex}
                      value={item?.value}
                      index={itemIndex}
                      isHint={!!item?.isHint}
                      onClick={this.removeFromAnswer}
                      disabled={isDisabled}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="hints-row">
          {chunkedHints.map((hintsChunk, chunkIndex) => {
            return (
              <div className="hints" key={chunkIndex}>
                {hintsChunk.map((hint, hintIndex) => {
                  const originalIndex =
                    chunkIndex * ELEMENTS_IN_A_ROW + hintIndex;
                  return (
                    <Cell
                      key={hintIndex}
                      value={hint}
                      index={originalIndex}
                      onClick={this.addToAnswer}
                      disabled={isDisabled}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
