import React, { Component } from "react";
import { Button, Icon, Loader, Dimmer } from "semantic-ui-react";
import PicsGrid from "../components/PicsGrid";
import InteractionPanel from "../components/InteractionPanel";
import firebase from "../Firebase.js";
import random from "lodash.random";

import "./MainGameContainer.css";
import UserContext from "../data/UserContext";
import ResultAudioManager from "../audio/ResultAudioManager";
import Analytics from "../data/Analytics";

const functions = firebase.app().functions("asia-east2");

const verifyAnswer = functions.httpsCallable("verifyAnswer");
const getAHint = functions.httpsCallable("getAHint");

const DimmedLoader = ({ className, active, children }) => {
  return (
    <Dimmer.Dimmable className={className} blurring dimmed={active}>
      <Dimmer active={active} inverted>
        <Loader active>Loading...</Loader>
      </Dimmer>
      {children}
    </Dimmer.Dimmable>
  );
};

export default class MainGameContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasInformedServer: false,
      hasServerErrored: false,
      knownHints: [],
      isRetrievingHint: false,
    };

    this.interactionPanel = React.createRef();
  }

  componentDidMount() {
    Analytics.updatePuzzleNo(this.props.puzzleData.puzzleNo);
  }

  informServer = (puzzleNo, answerAttempt) => {
    const { updateUserDetails } = this.props;
    verifyAnswer({ puzzleNo, answerAttempt })
      .then((result) => {
        // Read result of the Cloud Function.
        var { status, user } = result.data;

        updateUserDetails(user);
        if (status === "ACCEPTED") {
          this.setState({ hasInformedServer: true });
          return;
        }

        this.setState({ hasServerErrored: true });
      })
      .catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        // ...
        console.log(code, message, details);

        this.setState({ hasServerErrored: true });
      });
  };

  validateAndPromote = async (answer) => {
    const { hash } = this.props.puzzleData;
    const encoder = new TextEncoder();

    const answerHash = await crypto.subtle.digest(
      "SHA-1",
      encoder.encode(answer.toLowerCase())
    );

    const hashArray = Array.from(new Uint8Array(answerHash)); // convert buffer to byte array
    const answerHashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");

    if (hash === answerHashHex) {
      this.informServer(this.props.puzzleData.puzzleNo, answer);
      ResultAudioManager.playAudio("WIN");
      Analytics.logPuzzleCompletion(
        this.props.puzzleData.puzzleNo,
        this.state.knownHints.length
      );
      return "SUCCESS";
    }

    ResultAudioManager.playAudio("LOST");
    return "FAILURE";
  };

  revealHint = () => {
    const { updateUserDetails } = this.props;
    this.setState({ isRetrievingHint: true });
    const { puzzleData } = this.props;
    const { puzzleNo } = puzzleData;
    const { knownHints } = this.state;
    getAHint({ puzzleNo, knownHints })
      .then((result) => {
        var { hint, user } = result.data;

        updateUserDetails(user);
        this.setState(({ knownHints }) => {
          return { knownHints: [...knownHints, hint], isRetrievingHint: false };
        });
      })
      .catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        // ...
        console.log(error);
        this.setState({ isRetrievingHint: false });
      });
  };

  handleClearAll = () => {
    this.interactionPanel.current.clearAll();
  };

  render() {
    const { puzzleData, onNext, onBack } = this.props;
    const { puzzleNo, pics, hints, answerLength } = puzzleData;
    const {
      hasInformedServer,
      hasServerErrored,
      knownHints,
      isRetrievingHint,
    } = this.state;
    return (
      <div className="game-container">
        <DimmedLoader active={isRetrievingHint}>
          <div>
            <UserContext.Consumer>
              {({ userDetails, settings: { isMute }, toggleAudio }) => {
                return (
                  <div className={"top-row-wrap"}>
                    <button className="hintButton" onClick={onBack}>
                      <div className="iconButton">
                        <div className="iconHolder">
                          <img src="images/backIcon.png" height="16px"></img>
                        </div>
                      </div>
                    </button>
                    <p className="puzzleLevel">
                      Level{" "}
                      <span className="puzzleLevelNumber">#{puzzleNo}</span>
                    </p>
                    <div className="iconButton">
                      <div className="iconHolder">
                        <img src="images/coins.png" height="20px"></img>
                      </div>
                      <div className="value">{userDetails.tokens || 0}</div>
                    </div>
                    <button
                      className={
                        isMute ? " hintButton mute" : "hintButton unmute"
                      }
                      onClick={toggleAudio}
                    >
                      <div className="iconButton">
                        <div className="iconHolder">
                          <img
                            src={
                              isMute ? "images/mute.png" : "images/unmute.png"
                            }
                            name={isMute ? "mute" : "unmute"}
                          />
                        </div>
                      </div>
                    </button>
                  </div>
                );
              }}
            </UserContext.Consumer>
          </div>

          <div className={"pics-and-grid"}>
            <PicsGrid pics={pics} />
            <InteractionPanel
              ref={this.interactionPanel}
              puzzleNo={puzzleNo}
              answerSize={answerLength}
              hints={hints}
              onComplete={this.validateAndPromote}
              onNext={onNext}
              hasServerErrored={hasServerErrored}
              hasInformedServer={hasInformedServer}
              knownHints={knownHints}
              onRevealHint={this.revealHint}
            />
          </div>
          <div className="home-and-hint">
            <button className="hintButton" onClick={this.handleClearAll}>
              <div className="iconButton">
                <div className="iconHolder">
                  <img
                    style={{ marginLeft: 2 }}
                    src="images/reloadIcon.png"
                    height="18px"
                  ></img>
                </div>
              </div>
            </button>
            <UserContext.Consumer>
              {({ userDetails }) => {
                return (
                  <>
                    <button
                      className="hintButton"
                      content=""
                      labelPosition="right"
                      onClick={this.revealHint}
                      disabled={userDetails.tokens < 50}
                    >
                      <div className="iconButton">
                        <div className="iconHolder">
                          <img src="images/hintIcon.png" height="20px"></img>
                        </div>
                        <div className="value">Hint (50)</div>
                      </div>
                    </button>
                  </>
                );
              }}
            </UserContext.Consumer>
          </div>
        </DimmedLoader>
      </div>
    );
  }
}
