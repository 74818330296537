import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "./App.css";

import AppStateController from "./AppStateController";
import AdminPageContainer from "./containers/admin/AdminPageContainer";

function App() {
  return (
    <div className="main-container">
      <Router>
        <Switch>
          <Route path="/admin">
            <AdminPageContainer />
          </Route>
          <Route path="/">
            <AppStateController />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
