import * as firebase from "firebase/app";

import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";

var firebaseConfig = {
  apiKey: "AIzaSyDgNkN-XlDev4iMYotm66oYIboX6t8UoUU",
  authDomain: "padam.app",
  databaseURL: "https://padam-webapp-2020.firebaseio.com",
  projectId: "padam-webapp-2020",
  storageBucket: "padam-webapp-2020.appspot.com",
  messagingSenderId: "672736874009",
  appId: "1:672736874009:web:94d48f59d3b450a55d2488",
  measurementId: "G-PS0D4L1SGE",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
